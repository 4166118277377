import React from "react"
import { IActionButton } from "../../../../etc/types"

import styles from "../assets/css/index.module.scss"

const ActionButtonView: React.FC<IActionButton> = (props) => {
  const { title, href, target, rel, extraClasses } = props

  return (
    <a
      className={styles.actionButton + " shadow" + " " + (extraClasses ?? "")}
      target={target ? target : "_self"}
      rel={rel ? rel : "noopener noreferrer"}
      href={href}
    >
      {title}
    </a>
  )
}

export default ActionButtonView
