import { faCheckCircle, faCircleInfo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { NextPage } from "next"
import React, { useState } from "react"
import { Accordion, Collapse } from "react-bootstrap"
import { filterEventStringArrays, scrollToHTMLElement } from "../../../../../etc/functions"
import { IJuniorGrizzliesCampaign, IJuniorGrizzliesCampaignRaw } from "../../../../../etc/types"
import NBAPageWrapper from "../../../../layouts/nba-page-wrapper/views/NBAPageWrapper"
import ActionButtonView from "../../../../partials/action-button/views"
import FormWithTitleBlock from "../../../../partials/form-with-title-block/views"
import StaffCardsViewBlock from "../../../../partials/staff-card/views"
import staticData, {
	campaigns,
	faqList,
	featuredImageData,
	imageAssetsURL,
	ourMissionMeta,
	team
} from "../models/staticData"
import { Icon } from "./Icons"

import "swiper/css"
import "swiper/css/navigation"
import styles from "../assets/css/index.module.scss"
import Marquee from "react-fast-marquee"

// Function to calculate the total number of active events across any entity type
const calculateTotalActiveEvents = (entities: IJuniorGrizzliesCampaignRaw[]): number => {
	if (entities.length === 0) return 0
	return entities.reduce(
		// Accumulator 'res' starts at 0, and we add the number of filtered entities for each campaign
		(res, { events }) => res + filterEventStringArrays(events).length,
		0 // Initial value of 'res' is 0
	)
}

const totalActiveCamps = calculateTotalActiveEvents(campaigns.camps)
const totalActiveClinics = calculateTotalActiveEvents(campaigns.clinics)
const totalActiveOther = calculateTotalActiveEvents(campaigns.other)

// Make sure there are no more than 20 camps since we only have
// 20 images (1-20.jpg). Otherwise, you may need to add more images to the bucket.
let imageIndex = 0

const { actionButton } = featuredImageData

const BoilerplateView: NextPage = () => {
	return (
		<div className={styles.grizzYouthGlobalStyles}>
			<NBAPageWrapper {...staticData}>
				<div className={styles.grizzYouthStyles}>
					<main>
						<div className="main-title">
							<div className="mx-auto max-xxl">
								<div className="main-title-content">
									<h1 className="sr-only">Junior Grizzlies presented by OrthoSouth</h1>
									<img
										className="mx-auto img-fluid junior-grizzlies-logo"
										alt={staticData.title + " logo"}
										src={imageAssetsURL + "MG_2425_JrGrizzlies_RGB_onDark_FINAL.png"}
									/>

									<p>{ourMissionMeta}</p>

									<div className="associate-partner">
										<p>Associate Partner</p>
										<Icon slug="lebonheur-white" />
									</div>
									<ActionButtonView title="Sign Up for News" href="#contactFormScrollTo" />
								</div>
							</div>
						</div>

						<section id="leagues" className={`${styles.campaignSection} ${styles.leagues}`}>
							<div className={styles.campaignSectionTitle}>
								<Marquee
									autoFill
									className={styles.marquee}
									pauseOnHover
									speed={6}
									direction="right"
								>
									{[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
										<h2 key={item} className={styles.marqueeItem}>
											Leagues
										</h2>
									))}
								</Marquee>
							</div>

							<div className={styles.campaignSectionBody}>
								<div className="mx-auto max-xl">
									<div className={styles.leaguesGrid}>
										<div>
											<p className="mb-3">
												We team up with youth basketball leagues throughout the Mid-South to help
												enhance the participants’ overall experience through various benefits.
											</p>
											<p className="mb-4">
												League pricing and packages may vary. To learn more, locate and contact a
												league near you!
											</p>
										</div>

										<div className={styles.leaguesMapContainer}>
											<iframe src="https://www.google.com/maps/d/embed?mid=11TK3YQV8-P-UL3tT-HmbIF9hym-FRWY&hl=en&ehbc=2E312F" />
											{/* Trick to prevent map from zooming in/out on desktop when we scroll over */}
											<div
												className="overlay"
												onClick={(e) => (e.currentTarget.style.pointerEvents = "none")}
											/>
										</div>
									</div>
								</div>
							</div>
						</section>

						<section id="camps" className={`${styles.campaignSection}`}>
							<div className={styles.campaignSectionTitle}>
								<Marquee
									autoFill
									className={styles.marquee}
									pauseOnHover
									speed={6}
									direction="left"
								>
									{[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
										<h2 key={item} className={styles.marqueeItem}>
											Camps
										</h2>
									))}
								</Marquee>
							</div>

							<div className={styles.campaignSectionBody}>
								<div className="mx-auto max-xl">
									<p className="mb-0 mx-auto max-lg">
										Our camps are for boys and girls, ages 6-14, of all skill levels and led by our
										internal Junior Grizzlies staff. Camps vary in length between one full day to
										multiple days. Participants can expect an encouraging environment featuring
										different stations, competitive games, and fun contests.
									</p>

									<div className={`mx-auto ${styles.separator}`}></div>

									{totalActiveCamps > 0 ? (
										<>
											{campaigns.camps.map((camp) => (
												<CampaignBlock
													key={camp.title}
													title={camp.title}
													description={camp.description}
													events={filterEventStringArrays(camp.events)}
													url={camp.url}
												/>
											))}
										</>
									) : (
										<>
											<p>
												<strong>Currently, there are no scheduled camps.</strong>
											</p>
											<p>
												<strong>
													<a href="#contactFormScrollTo">Sign up for Junior Grizzlies News</a> to
													stay in the know about future camps and other programming.
												</strong>
											</p>
										</>
									)}
								</div>
							</div>
						</section>

						<section id="clinics" className={styles.campaignSection}>
							<div className={styles.campaignSectionTitle}>
								<Marquee
									autoFill
									className={styles.marquee}
									pauseOnHover
									speed={6}
									direction="right"
								>
									{[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
										<h2 key={item} className={styles.marqueeItem}>
											Clinics
										</h2>
									))}
								</Marquee>
							</div>

							<div className={styles.campaignSectionBody}>
								<div className="mx-auto max-xl">
									<p className="mb-0 mx-auto max-lg">
										Our clinics are for boys and girls, ages 6-14, of all skill levels and led by
										our internal Junior Grizzlies’ staff. Clinics are typically between 60 and 90
										minutes in length. Participants will experience a condensed version of our
										camps.
									</p>

									<div className={`mx-auto ${styles.separator}`}></div>

									{totalActiveClinics > 0 ? (
										<div className={styles.campaignGrid}>
											{campaigns.clinics.map((item) => (
												<CampaignBlock
													key={item.title}
													title={item.title}
													description={item.description}
													events={filterEventStringArrays(item.events)}
													url={item.url}
												/>
											))}
										</div>
									) : (
										<>
											<p>
												<strong>Currently, there are no scheduled clinics.</strong>
											</p>
											<p>
												<strong>
													<a href="#contactFormScrollTo">Sign up for Junior Grizzlies News</a> to
													stay in the know about future clinics and other programming.
												</strong>
											</p>
										</>
									)}
								</div>
							</div>
						</section>

						{totalActiveOther > 0 && (
							<section id="other-events" className={styles.campaignSection}>
								<div className={styles.campaignSectionTitle}>
									<Marquee
										autoFill
										className={styles.marquee}
										pauseOnHover
										speed={6}
										direction="left"
									>
										{[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
											<h2 key={item} className={styles.marqueeItem}>
												Other events
											</h2>
										))}
									</Marquee>
								</div>

								<div className={styles.campaignSectionBody}>
									<div className="mx-auto max-xl">
										<div className={styles.campaignGrid}>
											{campaigns.other.map((item) => (
												<CampaignBlock
													key={item.title}
													title={item.title}
													description={item.description}
													events={filterEventStringArrays(item.events)}
													url={item.url}
												/>
											))}
										</div>
									</div>
								</div>
							</section>
						)}

						<section className="meet-the-team">
							<div className={`mx-auto max-xl`}>
								<StaffCardsViewBlock
									{...{ title: "Meet the Team", items: team, assetsBaseURL: imageAssetsURL }}
								/>
							</div>
						</section>

						<section className="faq">
							<h2>FAQS</h2>
							<div className="py-xl-2" />
							<div className="faq-block">
								<div className="faq-img" />
								<div className="accordion-outer">
									<FAQList items={faqList} />
									<div className="faq-addon">
										<p className="pt-4">
											Still have questions? Let us help you!
											<span className="d-block py-2" />
											{actionButton && <ActionButtonView {...actionButton} />}
										</p>
									</div>
								</div>
							</div>
							<div className="py-xl-3" />
						</section>

						<div id="contactFormScrollTo" className={`${styles.formContainer}`}>
							<FormWithTitleBlock
								{...{
									formstackID: 4011719,
									formTitle: `<span className="text-center">Sign Up for News</span>`,
									className: styles.PSContainer,
									finalCallback: () => {
										// This is very important for mobile devices when the form is pretty long and after submission
										// we have to scroll user to the top part of the form to show the submission message exactly in viewport
										scrollToHTMLElement(document.getElementById("contactFormScrollTo"))
									}
								}}
							/>
						</div>
					</main>
				</div>
			</NBAPageWrapper>
		</div>
	)
}

export default BoilerplateView

export const CampaignBlock: React.FC<IJuniorGrizzliesCampaign> = ({
	title,
	description,
	events,
	url
}) => {
	const eventsQty = events.length

	if (!eventsQty) {
		return null
	}

	return (
		<div id={title.replace(/\s+/g, "-").toLowerCase()} className={styles.campaignSectionBlock}>
			<div className={styles.campaign}>
				{(title != "" || description) && (
					<div className={styles.campaignHeader}>
						{title != "" && <h3>{title}</h3>}

						{description &&
							description.split("\n").map((value, index) => <p key={index}>{value}</p>)}
					</div>
				)}

				<div className={styles.eventsGrid}>
					{events.map((value, index) => (
						<EventBlock key={index} {...{ url, item: value, total_qty: eventsQty }} />
					))}
				</div>
			</div>
		</div>
	)
}

export const EventBlock: React.FC<{ url: string; item: string[]; total_qty: number }> = (p) => {
	const [isOpen, setIsOpen] = useState(false)
	const { url, total_qty } = p
	const [dates, locationName, address, price, extraInfo, eventLink] = p.item

	return (
		<>
			<div className={`${styles.eventBlock} ${isOpen ? styles.open : ""}`}>
				<div
					onClick={() => setIsOpen(!isOpen)}
					aria-controls={dates.replace(/[\s,]+/g, "-").toLowerCase()}
					aria-expanded={isOpen}
					className={`${styles.eventBlockTrigger}`}
				>
					<div className="d-flex justify-content-between">
						<div>
							<h4>{locationName}</h4>
							<div className={styles.eventBlockListItem}>
								<Icon slug="calendar" />
								<span>{dates}</span>
							</div>
						</div>
						<div className="d-flex">
							{!isOpen ? (
								<p className="d-flex align-items-center align-self-end gap-2">
									<span className="d-none d-md-inline fs-6">More info</span>{" "}
									<FontAwesomeIcon icon={faCircleInfo} width={20} height={20} />
								</p>
							) : (
								<FontAwesomeIcon
									icon={faCheckCircle}
									width={30}
									height={30}
									className="align-self-start"
								/>
							)}
						</div>
					</div>

					<Collapse in={isOpen} className={styles.eventBlockCollapse}>
						<div id={dates.replace(/[\s,]+/g, "-").toLowerCase()}>
							<a
								href={"https://maps.google.com?q=" + address}
								target="_blank"
								className={styles.eventBlockListItem}
							>
								<Icon slug="geo-alt" />
								<span>{address}</span>
							</a>

							{price && price !== "" && (
								<div className={styles.eventBlockListItem}>
									<Icon slug="tag" />
									<span>
										<strong>{price.toLowerCase() === "free" ? price : "$" + price}</strong>
									</span>
								</div>
							)}

							{extraInfo && <div className={styles.helpText}>{extraInfo}</div>}

							<div>
								<a
									onClick={(e) => {
										e.stopPropagation()
									}}
									href={eventLink && eventLink !== "" ? eventLink : url}
									target="_blank"
									rel="noreferrer"
									className={styles.actionButton}
								>
									register
								</a>
							</div>
						</div>
					</Collapse>
				</div>
			</div>
		</>
	)
}

export const FAQList: React.FC<{ items: string[][] }> = (p) => (
	<Accordion flush>
		{p.items.map((value, index) => (
			<Accordion.Item key={index} eventKey={value[0]}>
				<Accordion.Header>
					<span dangerouslySetInnerHTML={{ __html: value[0] }} />
				</Accordion.Header>
				<Accordion.Body>
					<ul>
						{value.slice(1).map((value) => (
							<li key={value} dangerouslySetInnerHTML={{ __html: value }} />
						))}
					</ul>
				</Accordion.Body>
			</Accordion.Item>
		))}
	</Accordion>
)

const Video: React.FC<{ poster: string; src: string }> = (p) => (
	<>
		<div className="py-2" />
		<div className="ratio ratio-16x9">
			<div className="overflow-hidden">
				<video controls className="rounded shadow-sm" poster={p.poster} preload="metadata">
					<source src={p.src} type="video/mp4" />
					Download the <a href={p.src}>MP4</a>video.
				</video>
			</div>
		</div>
		<div className="py-3" />
	</>
)
