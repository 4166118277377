import { NextPage } from "next"
import { StaffCard, StaffCardBlock } from "../../../../etc/types"
import styles from "../assets/css/index.module.scss"


export const StaffCardView: NextPage<StaffCard> = (p) => {

	const { photo, name, title, focus, phone, email, assetsBaseURL } = p

	return (
		<div className={styles.staffCard}>
			<div className={"team-item " + photo}>
				<div className="meta">
					<img
						className="visually-hidden"
						alt={name + " " + title}
						src={assetsBaseURL + "staff/styled/" + photo}
					/>
					<div
						className="name"
						dangerouslySetInnerHTML={{ __html: name ?? "" }}
					/>
					<div
						className="title"
						dangerouslySetInnerHTML={{ __html: title ?? "" }}
					/>
					<div
						className="focus"
						dangerouslySetInnerHTML={{ __html: focus ?? "" }}
					/>
					<div className="yl" />
					<div className="phone">
						<a href={`tel:${phone}`}>{phone}</a>
					</div>
					<div className="email">
						<a href={`mailto:${email}`}>{email}</a>
					</div>
				</div>
			</div>

		</div>
	)
}


const StaffCardsViewBlock: NextPage<StaffCardBlock> = (p) => {

	const { title, items, assetsBaseURL } = p

	return <div className={styles.staffCardsBlock}>
		<h2>{title}</h2>
		<div className="row g-0 flex-row flex-nowrap flex-md-wrap scrolling-wrapper">
			{items.map((g, idx) => <div key={idx} className="col-10 col-md-6 col-xl-4 col-xl-3">
				<StaffCardView key={idx} {...{ ...g, assetsBaseURL }} /></div>
			)}
		</div>
	</div>

}


export default StaffCardsViewBlock